<template>
  <div>
    <!-- 面包屑导航区域 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>办学成果</el-breadcrumb-item>
      <el-breadcrumb-item>教研成果</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card>
      <div class="view-header">
        <el-page-header @back="goBack" :content="this.$route.query.pageTitle"></el-page-header>
      </div>
      <div class="sub-view-container">
        <div class="sub-view-head head_editor">
          <el-input class="inputC" placeholder="请在这里输入标题（50字以内）" maxlength='50' v-model="title"></el-input>
        </div>
        <div class="diveditor">
          <quill-editor
              v-model="content"
              ref="myQuillEditor"
              :options="editorOption"
              @change="onEditorChange($event)">
          </quill-editor>
        </div>
      </div>
      <div class="btneditor">
        <el-button type="primary" @click="preserveNews" :loading="btnLoading">保 存</el-button>
      </div>

      <!-- 视频上传 -->
      <el-dialog title="视频上传" width="592px" :visible.sync="videoUploadTag" :close-on-click-modal="false" @close="closedialogVideoUpload" append-to-body>
        <el-tabs v-model="activeName">
          <el-tab-pane label="添加视频链接" name="first">
            <el-input size="small" v-model="videoLink" placeholder="请输入视频链接" clearable></el-input>
          </el-tab-pane>
          <el-tab-pane label="本地上传" name="second">
            <el-upload
                class="avatar-uploader-videoWidget"
                action="#"
                :auto-upload="false"
                :show-file-list="false"
                :on-change="handleVideoChange"
            >
              <video v-if="videoUrl !=''" :src="videoUrl" class="video_avatar" controls="controls">您的浏览器不支持视频播放</video>
              <i v-else-if="videoUrl ==''" class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
            <p>只能上传mp4文件且文件不超过100MB</p>
          </el-tab-pane>
        </el-tabs>
        <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="videoUploadTag = false">取 消</el-button>
                <el-button size="small" type="primary" @click="addVideo" :loading="uploadVideoLoading">上 传</el-button>
            </span>
      </el-dialog>
    </el-card>
  </div>
</template>
<script>
import {Quill} from 'vue-quill-editor';
import {ImageExtend, QuillWatch} from 'quill-image-extend-module';
Quill.register('modules/ImageExtend', ImageExtend);
const fontSizeStyle = Quill.import("attributors/style/size");
fontSizeStyle.whitelist = ['12px', '14px', '16px', '20px', '24px', '36px'];
Quill.register(fontSizeStyle, true);
const toolOptions = [
  ['bold', 'italic', 'underline', 'strike'],
  ['blockquote', 'code-block'],
  [{'header': 1}, {'header': 2}],
  [{'list': 'ordered'}, {'list': 'bullet'}],
  [{'script': 'sub'}, {'script': 'super'}],
  [{'indent': '-1'}, {'indent': '+1'}],
  [{'direction': 'rtl'}],
  [{'size': fontSizeStyle.whitelist}],
  [{'header': [1, 2, 3, 4, 5, 6, false]}],
  [{'color': []}, {'background': []}],
  [{'align': []}],
  ['clean'],
  ['image', 'video']
];
import { addQuillTitle } from '../../assets/quill-title';
import axios from "axios";
/*import Api from "@/api/index";*/
export default {
  data () {
    return {
      title:'',//标题
      content: '',//文本编辑器
      pictureload:'',//图片是否加载完全
      /* source:'',//来源
       author: '',//作者*/
      editorOption: {
        placeholder:'请在这里输入...',
        modules: {
          ImageExtend: {
            name: 'file',  // 图片参数名
            size: 3,  // 可选参数 图片大小，单位为M，1M = 1024kb
              action: this.$http.defaults.baseURL + '/upload/image/upload',
            response: (res) => {
              return res.result;
            },
            headers: (xhr) => {
              xhr.setRequestHeader('authorization',localStorage.getItem('token'))
            },
            sizeError: () => {
              this.$message.error('图片不允许超过3M');
            },  // 图片超过大小的回调
            change: (xhr, formData) => {// 可选参数 选择图片触发，也可用来设置头部，但比headers多了一个参数，可设置formData
              formData.append('type', 'notice')
            }
          },
          toolbar: {
            container: toolOptions,  // 此次引入了全部工具栏
            handlers: {
              'image': function () {
                // 劫持原来的图片点击按钮事件
                QuillWatch.emit(this.quill.id)
              },
              // 视频点击事件
              'video': () => {
                this.videoUploadTag = true;
              }
            }
          }
        }
      },
      btnLoading:false,//保存按钮loading
      videoUploadTag:false,//上传视频弹窗
      activeName:'first',
      videoLink:'',//视频链接
      videofile:'',//视频文件
      videoUrl:'',//视频路径
      uploadVideoLoading:false,//上传视频按钮loading
    }
  },
  head: {
    title: '教研成果',
  },
  components:{

  },
  mounted(){
    addQuillTitle();
    if(this.$route.query.id){//编辑
      this.getContent(this.$route.query.id);
    }
  },

  methods:{
    async getContent(id) {
      const { data: res } = await this.$http.get('/activity/detail', { params: { id: id, type: 5 }})
      this.loading = false;
      if (res.code !== 200) {
        this.$message.error(res.message)
      } else {
        this.content = res.result.content
        this.title = res.result.title
      }
    },
    //返回上一页
    goBack() {
      this.$router.go(-1);
    },
    /*      编辑器相关事件     */
    // 内容改变事件
    onEditorChange(editor){
      let iurl = editor.html;
      if(!editor.text.trim() && iurl.indexOf('<img') == '-1'){
        this.content = '';
      }else{
        this.content = editor.html;
      }
      let pictureload = editor.text;
      this.pictureload =  pictureload.indexOf('[uploading');
    },
    //保存
    async preserveNews(){
      if(this.title === '' || this.title == null){
        return this.$message.error('请输入标题！');
      }
      if(this.content === '' || this.content == null){
        return this.$message.error('请输入内容！');
      }
      /*if(this.pictureload !== '-1'){
        return this.$message.error('请稍等，内容中包含图片正在生成！');
      }*/
      this.btnLoading = true;
      let imgReg = /<img.*?(?:>|\/>)/gi;
      let srcReg = /<img\s+src="(.*?)"/ig;
      let str = this.content;
      let arr = str.match(imgReg);
      let srcArr = [];
      let pictureUrl = '';
      let resawait = null;
      if(arr != null){
        for (let i = 0; i < arr.length; i++) {
          while(srcReg.test(arr[i])){
            srcArr.push(RegExp.$1)
          }
        }
          pictureUrl = srcArr.length > 0 ? srcArr[0].replace(/amp;/g,"") : '';
      }
      let formData = {
        title: this.title,
        content: this.content,
        picture: pictureUrl,
        type: 5
      }
      let result;
      if(this.$route.query.id) {//编辑
        formData.id = this.$route.query.id
        result = await this.$http.put('/activity/update', formData)
      }else {//添加
        result = await this.$http.post('/activity/add', formData)
      }
      let res = result.data;
      this.btnLoading = false;
      if (res.code !== 200) {
        this.$message.error(res.message)
      } else {
        this.addDialogVisible = false
        this.$message.success(res.message)
        await this.$router.push('/education')
      }
    },
    //关闭上传视频弹窗
    closedialogVideoUpload(){
      this.activeName = 'first';
      this.videoLink = '';
      this.videoUrl = '';
      this.uploadVideoLoading = false;
    },
    //上传视频change事件
    handleVideoChange({raw}){
      if (['video/mp4', 'video/ogg', 'video/flv','video/avi','video/wmv','video/rmvb'].indexOf(raw.type) == -1) {
        this.$message.error('请上传正确的视频格式');
        return false;
      }
      let src = window.webkitURL.createObjectURL(raw);
      let fileSize = raw.size
      let MaxSize = 100*1024*1024;
      if(fileSize > MaxSize) {
        this.$message.error('文件不能超过100MB！');
      } else {
        this.videoUrl = src;
        this.videofile = raw;
      }
    },
    //保存上传视频
    async addVideo(){
      if(this.activeName == 'first'){
        if (this.videoLink.length == 0) {
          return this.$message.error('请输入视频链接');
        }
        this.insertVideo();
      }else{
        if(this.videofile == ''){
          return this.$message.error('请选择上传的视频');
        }
        this.uploadVideoLoading = true;
        // 先创建一个FormData 对象
        let formData = new FormData();
        // 将请求参数一个一个append进去，因为涉及到项目内部东西，而且需要安全请求，所以就随便写了几个键值代替
        formData.append('file',this.videofile);
        formData.append('type', 'notice');
        await this.$http({
          url: '/upload/image/upload',
          data: formData,
          headers: {
            'Content-Type': "multipart/form-data; charset=UTF-8",
          },
          method:'post',
          timeout: 300000
        }).then(res => {
          let result = res.data;
          if (result.code === 200) {
            this.videoLink = result.result;
            this.insertVideo();
          } else {
            this.uploadVideoLoading = false;
            this.$message.error(result.message);
          }
        }).catch(error => {
          this.uploadVideoLoading = false;
          this.$message.error('连接服务器失败!');
        });
      }
    },
    //插入视频
    insertVideo(){
      //当编辑器中没有输入文本时，这里获取到的 range 为 null
      var range = this.$refs.myQuillEditor.quill.selection.savedRange.index;
      //视频插入在富文本中的位置
      var index = 0;
      if (range == null) {
        index = 0;
      } else {
        index = range.index;
      }
      //隐藏弹框
      this.videoUploadTag = false;
      //将视频链接插入到当前的富文本当中
      this.$refs.myQuillEditor.quill.insertEmbed(index, 'video', this.videoLink);
      this.$refs.myQuillEditor.quill.insertEmbed(index+1, '');
    }
  }
}
</script>
<style scoped>
.view-header{
  margin:0 -24px;
  padding:0 24px 20px;
  border-bottom:1px solid #E9E9E9;
}
.sub-view-container{width: 100%;margin:0 auto;margin-top:24px;}
.head_editor{
  padding: 12px 0;
  background-color: #F3F3F3;
}
.head_editor .inputC{ font-size: 24px; color: #20243A; border: none; width: 80%; padding: 0 20px; }
.head_editor .inputC::-webkit-input-placeholder { color: #20243A; }
.diveditor{margin-top:16px;height: 390px;}
.view-source{display:flex;align-items:center;padding:8px 24px;background-color:#F3F3F3;color:#666666;}
.view-source .el-input{flex:1;}
.btneditor{margin-top:16px;text-align:center;}
.btneditor .el-button{width:96px;}
.avatar-uploader-videoWidget{
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  overflow: hidden;
}
.avatar-uploader-videoWidget .video_avatar{
  max-width:500px;
  height: 200px;
}
</style>
<style>
.sub-view-container .head_editor .el-input__inner{ background-color: #F3F3F3; border: none; }
.sub-view-container .diveditor .ql-toolbar.ql-snow{ border: 1px solid #E4E8EB; }
.sub-view-container .diveditor .ql-editor.ql-blank, .ql-editor {min-height: 350px; max-height: 450px;}
.sub-view-container .diveditor .ql-container.ql-snow{ border: 1px solid #E4E8EB;}
.ql-container {
  height: 350px !important;
}
.avatar-uploader-videoWidget .el-upload{
  width:99%;
  height:98%;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader-videoWidget .el-upload .avatar-uploader-icon{
  padding-top:inherit;
}
</style>
